const socials = [
  {
    id: 1,
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/weschap/",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/e/e8/Linkedin-logo-blue-In-square-40px.png",
  },
  {
    id: 2,
    title: "GitHub",
    link: "https://github.com/Nukambe",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
  },
  // {
  //   id: 3,
  //   title: "Email",
  //   link: "mailto:chappellwesley@gmail.com",
  //   image:
  //     "https://upload.wikimedia.org/wikipedia/commons/4/43/NotoSans_-_Back_Of_Envelope_-_1F582.svg",
  // },
];

export default socials;
